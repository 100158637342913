import React from 'react';

import HomePageContentSection from './contentSection';
import HomePageLatestLinks from './latestLinks';
import HomePageBreakSection from './breakSection';
import { getContent } from './utils';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { HomeQuery } from '../../../types/graphql-types';

type RLPHomeProps = {
  data: HomeQuery;
};

const RLPHome: React.FC<RLPHomeProps> = ({ data }: RLPHomeProps) => {
  const { contentSectionsById, postListsById } = getContent(data);
  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap container mx-auto mt-8 md:mt-10">
        {contentSectionsById['this-is-rlp'] && (
          <div className="this-is-rlp flex-1">
            <HomePageContentSection data={contentSectionsById['this-is-rlp']} />
          </div>
        )}
        {postListsById.latest && (
          <div className="latest-posts flex-initial ml-0 md:ml-8">
            <HomePageLatestLinks postList={postListsById.latest} />
          </div>
        )}
      </div>
      <div className="slavery-is-both-our-legacies md:mt-8 md:py-5 bg-black">
        {contentSectionsById['slavery-is-both-our-legacies'] && (
          <HomePageBreakSection
            sectionId="slavery-is-both-our-legacies"
            data={contentSectionsById['slavery-is-both-our-legacies']}
          />
        )}
      </div>
      {/* <div className="flex flex-wrap md:flex-nowrap container mx-auto mt-8"> */}
      {/*  {contentSectionsById['repair-the-damage'] && ( */}
      {/*    <div className="repair-the-damage flex-1"> */}
      {/*      <HomePageContentSection */}
      {/*        data={contentSectionsById['repair-the-damage']} */}
      {/*      /> */}
      {/*    </div> */}
      {/*  )} */}
      {/*  {postListsById.latest && ( */}
      {/*    <div className="latest-posts flex-initial ml-0 md:ml-8"> */}
      {/*      <HomePageLatestLinks postList={postListsById.latest} /> */}
      {/*    </div> */}
      {/*  )} */}
      {/* </div> */}
      <div className="h-52">Testimonials/Infographic here</div>
      <div className="what-will-your-legacy-be md:mt-8 md:py-5 bg-black">
        {contentSectionsById['what-will-your-legacy-be'] && (
          <HomePageBreakSection
            sectionId="what-will-your-legacy-be"
            data={contentSectionsById['what-will-your-legacy-be']}
          />
        )}
      </div>
      <div className="flex flex-wrap md:flex-nowrap container mx-auto mt-8">
        {contentSectionsById['repair-the-damage'] && (
          <div className="repair-the-damage flex-1">
            <HomePageContentSection
              data={contentSectionsById['repair-the-damage']}
            />
          </div>
        )}
        {postListsById.latest && (
          <div className="latest-posts flex-initial ml-0 md:ml-8">
            <HomePageLatestLinks postList={postListsById.latest} />
          </div>
        )}
      </div>
    </>
  );
};

export default RLPHome;
