import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Header1, Header2 } from '../headers';
import Video from '../video';
import ContentfulRichText from '../contentfulRichText';
import LinkComponent, { CTALink } from '../linkComponent';
import { HomePageContent } from './types';

type HomePageContentSectionProps = {
  data: HomePageContent;
};

const HomePageContentSection: React.FC<HomePageContentSectionProps> = ({
  data,
}: HomePageContentSectionProps) => {
  const {
    sectionId,
    header,
    video,
    photo,
    content,
    callToAction,
    callToActionLink,
  } = data;
  return (
    <div className="mb-8 md:mb-0">
      <Header1
        tag={sectionId === 'white-reparations-to-african-people' ? 'h1' : 'h2'}
        color="black"
        mobileColorsFlipped
        className="uppercase mx-5 md:mx-0 pt-8 md:pt-0 border-t-4 border-white md:border-t-0"
      >
        {header}
      </Header1>
      <div className="mt-2">
        {video && photo && (
          <Video videoUrl={video} photo={photo} title={header || ''} />
        )}
        {!video && photo && (
          <div className="w-full">
            {callToAction && callToActionLink && (
              <LinkComponent link={callToActionLink}>
                <GatsbyImage
                  alt="TODO: add alt tag"
                  style={{ width: '100%' }}
                  image={photo?.gatsbyImageData}
                />
              </LinkComponent>
            )}
            {(!callToAction || !callToActionLink) && (
              <GatsbyImage
                alt="TODO: add alt tag"
                style={{ width: '100%' }}
                image={photo?.gatsbyImageData}
              />
            )}
          </div>
        )}
      </div>
      <div className="mt-2">
        <Header2 color="black" className="hidden md:block" tag="h3">
          <ContentfulRichText document={content} />
        </Header2>
        <div className="text-white block md:hidden">
          <ContentfulRichText document={content} />
        </div>
      </div>

      {callToAction && callToActionLink && (
        <div className="mt-2 inline-block mx-5 md:mx-0">
          <CTALink link={callToActionLink} text={callToAction} />
        </div>
      )}
    </div>
  );
};

export default HomePageContentSection;
