import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Header1, Header2 } from '../headers';
import { HomePagePoliticalEducationList } from './types';
import ContentfulRichText from '../contentfulRichText';
import LinkComponent from '../linkComponent';

import './styles/politicalEducation.css';

type HomePagePoliticalEducationProps = {
  data: HomePagePoliticalEducationList;
};

const HomePagePoliticalEducation: React.FC<HomePagePoliticalEducationProps> = ({
  data,
}: HomePagePoliticalEducationProps) => (
  <div className="political-education min-w-full md:min-w-0 flex-1">
    <Header1
      color="black"
      mobileColorsFlipped
      className="uppercase mx-0 hidden md:block"
    >
      Political Education
    </Header1>
    <div className="flex flex-wrap md:mt-6 mx-0 md:-mx-4">
      {data.sections?.map((section) => {
        if (!section) {
          return null;
        }
        return (
          <div
            key={section.id}
            className="flex flex-col w-full md:w-1/2 px-0 md:px-4 mb-8"
          >
            <Header2
              color="white"
              className="block md:hidden mx-5 order-1 pt-8 border-t-4 border-white"
            >
              <LinkComponent link={section.link} linkClassName="text-white">
                {section.header}
              </LinkComponent>
            </Header2>
            <div className="political-education-image w-full order-2">
              {section.photoMobile && (
                <div className="block md:hidden w-full">
                  <LinkComponent link={section.link}>
                    <div className="w-full pt-2">
                      <GatsbyImage
                        alt="TODO: add alt tag"
                        image={section.photoMobile.gatsbyImageData}
                      />
                    </div>
                  </LinkComponent>
                </div>
              )}
              {section.photoDesktop && (
                <div className="hidden md:block w-full">
                  <LinkComponent link={section.link}>
                    <div className="w-full pt-2">
                      <GatsbyImage
                        alt="TODO: add alt tag"
                        image={section.photoDesktop.gatsbyImageData}
                      />
                    </div>
                  </LinkComponent>
                </div>
              )}
            </div>
            <Header2
              color="black"
              className="political-education-text hidden md:block order-1"
            >
              <LinkComponent link={section.link} flippedHover>
                {section.header}
              </LinkComponent>
            </Header2>

            <div className="mt-2 text-white md:text-black order-4">
              <ContentfulRichText document={section.description} />
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default HomePagePoliticalEducation;
