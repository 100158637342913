import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  RefObject,
} from 'react';
import throttle from 'lodash.throttle';

import { isSSR, getRefElement } from '../index';

interface UseEventListener {
  type: keyof WindowEventMap;
  listener: EventListener;
  element?: RefObject<Element> | Document | Window | null;
  options?: AddEventListenerOptions;
}

export const useEventListener = ({
  type,
  listener,
  // eslint-disable-next-line no-undef
  element = isSSR ? undefined : window,
  options,
}: UseEventListener): void => {
  const savedListener = useRef<EventListener>();

  useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  const handleEventListener = useCallback((event: Event) => {
    if (savedListener.current) {
      savedListener.current(event);
    }
  }, []);

  useEffect(() => {
    const target = getRefElement(element);
    if (target) {
      target.addEventListener(type, handleEventListener, options);
    }
    return (): void => {
      if (target) {
        target.removeEventListener(type, handleEventListener);
      }
    };
  }, [type, element, options, handleEventListener]);
};

interface Scroll {
  y?: number;
  x?: number;
  direction?: 'up' | 'right' | 'down' | 'left';
}

interface UseScroll {
  wait?: number;
  element?: RefObject<Element> | Window | null;
}

export const useScroll = (options?: UseScroll): Scroll => {
  const { wait, element } = useMemo<UseScroll>(
    () => ({
      wait: 250,
      // eslint-disable-next-line no-undef
      element: isSSR ? undefined : window,
      ...options,
    }),
    [options]
  );

  const getScrollOffset = useCallback(
    (direction: 'y' | 'x'): number | undefined => {
      const target = getRefElement(element);

      if (isSSR || !target) {
        return undefined;
      }

      if ('window' in target) {
        return direction === 'y' ? target.pageYOffset : target.pageXOffset;
      }

      if ('nodeType' in target) {
        return direction === 'y' ? target.scrollTop : target.scrollLeft;
      }

      return undefined;
    },
    [element]
  );

  const [scroll, setScroll] = useState<Scroll>({
    y: getScrollOffset('y'),
    x: getScrollOffset('x'),
    direction: undefined,
  });

  const setDirection = useCallback(
    ({ y, x }: Scroll): 'up' | 'down' | 'left' | 'right' | undefined => {
      const yOffset = getScrollOffset('y');
      const xOffset = getScrollOffset('x');

      if (
        y !== undefined &&
        x !== undefined &&
        yOffset !== undefined &&
        xOffset !== undefined
      ) {
        if (y > yOffset) return 'up';
        if (y < yOffset) return 'down';
        if (x > xOffset) return 'left';
        if (x < xOffset) return 'right';
      }

      return undefined;
    },
    [getScrollOffset]
  );

  const scrollFunc = useCallback(() => {
    const yOffset = getScrollOffset('y');
    const xOffset = getScrollOffset('x');

    setScroll(
      (prev: Scroll): Scroll => ({
        y: yOffset,
        x: xOffset,
        direction: setDirection(prev),
      })
    );
  }, [getScrollOffset, setDirection]);

  const handleScroll = useMemo(
    () =>
      wait !== 0
        ? throttle(() => scrollFunc(), wait, { leading: true, trailing: true })
        : (): void => scrollFunc(),
    [wait, scrollFunc]
  );

  useEventListener({
    type: 'scroll',
    listener: handleScroll,
    element,
    options: { passive: true },
  });

  return scroll;
};

interface UseResize {
  callback: (event: Event) => void;
  wait?: number;
}

export const useResize = (input: UseResize): void => {
  const { wait } = useMemo<UseScroll>(
    () => ({
      wait: typeof input.wait === 'number' ? input.wait : 250,
    }),
    [input.wait]
  );
  const { callback } = input;

  const handleResize = useMemo(
    () =>
      wait !== 0
        ? throttle((event: Event) => callback(event), wait, {
            leading: true,
            trailing: false,
          })
        : (event: Event) => callback(event),
    [wait, callback]
  );

  useEventListener({
    type: 'resize',
    listener: handleResize,
    options: { passive: true },
  });
};

export const useClickOutside = (
  element: RefObject<Element> | null,
  callback: (event: MouseEvent) => void
): void => {
  const handleClick = useCallback(
    (event) => {
      if (!getRefElement(element)?.contains(event.target)) {
        callback(event);
      }
    },
    [callback, element]
  );

  useEventListener({
    type: 'click',
    listener: handleClick,
  });
};

export const useIsDesktop = (): boolean => {
  // consider 'isDesktop' true if isSSR
  const checkIfDesktop = (): boolean =>
    isSSR ? true : window.matchMedia('(min-width: 768px)').matches;

  const [isDesktop, setIsDesktop] = useState<boolean>(checkIfDesktop());

  useResize({
    callback: () => {
      setIsDesktop(checkIfDesktop());
    },
    wait: 200,
  });

  return isDesktop;
};

export default { useScroll, useResize, useClickOutside, useIsDesktop };
