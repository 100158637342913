import {
  HomePageContent,
  HomePageEventList,
  HomePagePoliticalEducationList,
  HomePagePostList,
} from './types';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { HomeQuery } from '../../../types/graphql-types';

type ContentById = {
  contentSectionsById: Record<string, HomePageContent>;
  postListsById: Record<string, HomePagePostList>;
  eventListsById: Record<string, HomePageEventList>;
  homePagePoliticalEducationList?: HomePagePoliticalEducationList;
};

export const getContent = (data: HomeQuery): ContentById => {
  const {
    allContentfulHomeContent: { edges: homePageContentArray },
    allContentfulFeaturedPosts: { edges: homePagePostListArray },
    allContentfulPoliticalEducationList: {
      edges: homePagePoliticalEducationListArray,
    },
    allContentfulFeaturedEvents: { edges: homePageEventListArray },
  } = data;

  const contentSectionsById: Record<
    string,
    HomePageContent
  > = homePageContentArray.reduce(
    (acc: Record<string, HomePageContent>, contentSection) => {
      acc[contentSection.node.sectionId || ''] = contentSection.node;
      return acc;
    },
    {}
  );

  const postListsById: Record<
    string,
    HomePagePostList
  > = homePagePostListArray.reduce(
    (acc: Record<string, HomePagePostList>, postList) => {
      acc[postList.node.listId || ''] = postList.node;
      return acc;
    },
    {}
  );

  const eventListsById: Record<
    string,
    HomePageEventList
  > = homePageEventListArray.reduce(
    (acc: Record<string, HomePageEventList>, eventList) => {
      acc[eventList.node.listId || ''] = eventList.node;
      return acc;
    },
    {}
  );

  let homePagePoliticalEducationList;
  if (
    !homePagePoliticalEducationListArray ||
    homePagePoliticalEducationListArray.length === 0
  ) {
    homePagePoliticalEducationList = undefined;
  } else {
    [
      { node: homePagePoliticalEducationList },
    ] = homePagePoliticalEducationListArray;
  }
  return {
    contentSectionsById,
    postListsById,
    eventListsById,
    homePagePoliticalEducationList,
  };
};

export default { getContentById: getContent };
