import React from 'react';

import {
  MenuProps,
  MenuItem,
  MenuItems,
  SubMenu,
  CTARightMenu,
  CTAItem,
} from '../headerMenu';

import LinkComponent from '../../linkComponent';
import { getBaseTextColor, isSSR } from '../../../utils';

import '../menu.css';
import { useIsDesktop } from '../../../utils/hooks';

const DynamicHomePageMenu = React.lazy(() => import('./dynamicHomePageMenu'));

type SubMenuDropdownProps = {
  text: string;
  link?: string;
  subMenu: SubMenu;
};

const SubMenuDropdown: React.FC<SubMenuDropdownProps> = ({
  text,
  link,
  subMenu,
}: SubMenuDropdownProps) => {
  const linkHoverClass = getBaseTextColor(true);
  return (
    <div className="dropdown-parent">
      {link && (
        <div className={`sub-menu-text ${linkHoverClass}`}>
          <LinkComponent link={link} flippedHover>
            {text}
          </LinkComponent>
        </div>
      )}
      {!link && <span className="sub-menu-text cursor-pointer">{text}</span>}
      <div
        className="absolute left-0 pt-3 dropdown-child cursor-default z-9999"
        style={{ top: '100%', minWidth: 250 }}
      >
        <div className="bg-white shadow-sm px-3 py-2 font-normal normal-case">
          {subMenu.map((subMenuItem) => {
            if (
              subMenuItem &&
              subMenuItem.text &&
              subMenuItem.link &&
              subMenuItem.appearsOnHomepage
            ) {
              return (
                <div className="py-2" key={subMenuItem.id}>
                  <LinkComponent link={subMenuItem.link} flippedHover>
                    {subMenuItem.text}
                  </LinkComponent>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

SubMenuDropdown.defaultProps = {
  link: undefined,
};

type MenuItemProps = {
  menuItem: MenuItem;
};

const HomePageMenuItem: React.FC<MenuItemProps> = ({
  menuItem,
}: MenuItemProps) => {
  const { text, link, subMenu } = menuItem;
  const linkHoverClass = getBaseTextColor(true);
  return (
    <>
      {text && (
        <div className="mr-5 uppercase font-medium relative home-page-menu-item">
          {link && !subMenu && (
            <div className={linkHoverClass}>
              <LinkComponent link={link} flippedHover>
                {text}
              </LinkComponent>
            </div>
          )}
          {subMenu && (
            <SubMenuDropdown
              link={link || undefined}
              text={text}
              subMenu={subMenu}
            />
          )}
        </div>
      )}
    </>
  );
};

type HomePageTopOfPageMenuProps = {
  menuItems: MenuItems;
};

export const HomePageTopOfPageMenu: React.FC<HomePageTopOfPageMenuProps> = ({
  menuItems,
}: HomePageTopOfPageMenuProps) => (
  <div className="flex top-of-page-menu">
    {menuItems.map((menuItem) => {
      if (!menuItem) {
        return null;
      }
      return <HomePageMenuItem key={menuItem.id} menuItem={menuItem} />;
    })}
  </div>
);

export type HomePageMenuChildProps = {
  menuItems: MenuItems;
  ctaItem?: CTAItem;
  secondaryCtaItem?: CTAItem;
  hasCtaItems: boolean;
};

const StaticHomePageMenu: React.FC<HomePageMenuChildProps> = ({
  menuItems,
  ctaItem,
  secondaryCtaItem,
  hasCtaItems,
}: HomePageMenuChildProps) => (
  <>
    <nav className="z-50 bg-white w-full" style={{ top: -1 }}>
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <HomePageTopOfPageMenu menuItems={menuItems} />
          {hasCtaItems && (
            <CTARightMenu
              ctaItem={ctaItem}
              secondaryCtaItem={secondaryCtaItem}
            />
          )}
        </div>
      </div>
    </nav>
  </>
);

StaticHomePageMenu.defaultProps = {
  ctaItem: undefined,
  secondaryCtaItem: undefined,
};

type HomePageMenuProps = MenuProps & {
  onSideMenuToggle: () => void;
  sideMenuToggled: boolean;
};

const HomePageMenu: React.FC<HomePageMenuProps> = ({
  data,
  onSideMenuToggle,
  sideMenuToggled,
}: HomePageMenuProps) => {
  const isDesktop = useIsDesktop();

  let menuItems: MenuItems = [];
  if (data?.items && data?.items?.length > 0) {
    menuItems = data?.items?.filter((item) => item?.appearsOnHomepage);
  }

  const ctaItem =
    data?.ctaItem?.text && data?.ctaItem?.link ? data.ctaItem : undefined;
  const secondaryCtaItem =
    data?.secondaryCtaItem?.text && data?.secondaryCtaItem?.link
      ? data.secondaryCtaItem
      : undefined;

  const hasCtaItems = !!(ctaItem || secondaryCtaItem);

  return (
    <div className="hidden md:block">
      {isSSR && (
        <StaticHomePageMenu
          menuItems={menuItems}
          ctaItem={ctaItem}
          secondaryCtaItem={secondaryCtaItem}
          hasCtaItems={hasCtaItems}
        />
      )}
      {!isSSR && isDesktop && (
        <React.Suspense
          fallback={
            <StaticHomePageMenu
              menuItems={menuItems}
              ctaItem={ctaItem}
              secondaryCtaItem={secondaryCtaItem}
              hasCtaItems={hasCtaItems}
            />
          }
        >
          <DynamicHomePageMenu
            menuItems={menuItems}
            ctaItem={ctaItem}
            secondaryCtaItem={secondaryCtaItem}
            hasCtaItems={hasCtaItems}
            onSideMenuToggle={onSideMenuToggle}
            sideMenuToggled={sideMenuToggled}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default HomePageMenu;
