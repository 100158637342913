import React from 'react';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { HomeQuery } from '../../../types/graphql-types';
import HomePageContentSection from './contentSection';
import HomePageLatestLinks from './latestLinks';
import HomePageBreakSection from './breakSection';
import HomePagePoliticalEducation from './politicalEducation';
import { getContent } from './utils';

type USMHomeProps = {
  data: HomeQuery;
};

const USMHome: React.FC<USMHomeProps> = ({ data }: USMHomeProps) => {
  const {
    contentSectionsById,
    postListsById,
    eventListsById,
    homePagePoliticalEducationList,
  } = getContent(data);

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap container mx-auto mt-8 md:mt-10">
        {contentSectionsById['white-reparations-to-african-people'] && (
          <div className="white-reparations-to-african-people flex-1">
            <HomePageContentSection
              data={contentSectionsById['white-reparations-to-african-people']}
            />
          </div>
        )}
        {postListsById['home-page-latest'] && (
          <div className="latest-posts flex-initial ml-0 md:ml-8">
            <HomePageLatestLinks postList={postListsById['home-page-latest']} />
          </div>
        )}
      </div>
      <div className="white-reparations-to-african-people md:mt-8 md:py-5 bg-black overflow-hidden relative">
        {contentSectionsById['black-power-blueprint'] && (
          <HomePageBreakSection
            sectionId="black-power-blueprint"
            data={contentSectionsById['black-power-blueprint']}
          />
        )}
      </div>
      <div className="flex flex-wrap md:flex-nowrap container mx-auto mt-8">
        {contentSectionsById['our-leadership'] && (
          <div className="our-leadership flex-1">
            <HomePageContentSection
              data={contentSectionsById['our-leadership']}
            />
          </div>
        )}
        {postListsById.campaigns && (
          <div className="latest-posts flex-initial ml-0 md:ml-8">
            <HomePageLatestLinks postList={postListsById.campaigns} />
          </div>
        )}
      </div>
      <div className="reparations-legacy-project md:mt-8 md:py-5 bg-black overflow-hidden relative">
        {contentSectionsById['reparations-legacy-project'] && (
          <HomePageBreakSection
            sectionId="reparations-legacy-project"
            data={contentSectionsById['reparations-legacy-project']}
          />
        )}
      </div>
      <div className="flex flex-wrap md:flex-nowrap container mx-auto mt-8">
        {homePagePoliticalEducationList &&
          homePagePoliticalEducationList.sections && (
            <HomePagePoliticalEducation data={homePagePoliticalEducationList} />
          )}
        {eventListsById['home-page-latest-events'] && (
          <div className="latest-posts flex-initial ml-0 md:ml-8">
            <HomePageLatestLinks
              eventList={eventListsById['home-page-latest-events']}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default USMHome;
