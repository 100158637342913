import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import LogoImage from './logoImage';
import { Header1 } from './headers';
import {
  ContentfulAsset,
  ContentfulHomePageBanner,
  Maybe,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import LinkComponent from './linkComponent';

export type HomeBanner = Pick<
  ContentfulHomePageBanner,
  'header' | 'callToAction' | 'callToActionLink'
> & {
  backgroundDesktop?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
  backgroundMobile?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
};

const DefaultText: React.FC = () => (
  <>
    White Reparations
    <br />
    to African People
  </>
);

const Default: React.FC = () => (
  <header className="bg-black py-4">
    <div
      className="container mx-auto flex flex-col justify-between"
      style={{ height: 400 }}
    >
      <LogoImage color="white" />
      <h2 className="text-white text-6xl uppercase w-1/2">
        <DefaultText />
      </h2>
    </div>
  </header>
);

type BannerImageProps = {
  backgroundMobile: Pick<ContentfulAsset, 'gatsbyImageData'>;
  backgroundDesktop: Pick<ContentfulAsset, 'gatsbyImageData'>;
};

const BannerImage: React.FC<BannerImageProps> = ({
  backgroundMobile,
  backgroundDesktop,
}: BannerImageProps) => {
  let mobileHeightClass = 'h-64';
  if (process.env.GATSBY_DOMAIN_ID === 'rlp') {
    mobileHeightClass = 'h-48';
  }
  return (
    <>
      <div
        id="banner-image"
        className={`absolute transform -translate-x-1/2 ${mobileHeightClass} md:h-auto w-full md:w-auto md:min-w-screen-2xl`}
        style={{ left: '50%' }}
      >
        {backgroundMobile && backgroundDesktop && (
          <>
            <div className="absolute left-0 top-0 block md:hidden h-full w-full">
              <GatsbyImage
                alt="TODO: add alt tag"
                image={backgroundMobile.gatsbyImageData}
                imgStyle={{ width: '100%', height: '100%' }}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            <div className="hidden md:block">
              <GatsbyImage
                alt="TODO: add alt tag"
                image={backgroundDesktop.gatsbyImageData}
              />
            </div>
          </>
        )}
        <div
          id="banner-fade-up"
          className="absolute  left-0 w-full bg-gradient-to-t to-black from-transparent"
          style={{ height: 200, top: -20 }}
        />
        <div
          id="banner-fade-left"
          className="hidden md:block absolute top-0 bottom-0 left-0 bg-gradient-to-r from-black to-transparent"
          style={{
            width: 100,
            marginLeft: -2,
          }}
        />
        <div
          id="banner-fade-right"
          className="hidden md:block absolute top-0 bottom-0 right-0 bg-gradient-to-r from-transparent to-black"
          style={{
            width: 100,
            marginRight: -2,
          }}
        />
        <div
          id="banner-fade-down"
          className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent"
          style={{ height: 200 }}
        />
      </div>
    </>
  );
};

type CTAProps = {
  color: 'white' | 'black';
  callToAction: string;
  callToActionLink: string;
};

const CTA: React.FC<CTAProps> = ({
  color,
  callToAction,
  callToActionLink,
}: CTAProps) => {
  const textClass = color === 'white' ? 'text-white' : 'text-black';
  return (
    <div className="mt-2 inline-block">
      <LinkComponent
        link={callToActionLink}
        linkClassName={textClass}
        flippedHover
      >
        {callToAction}
      </LinkComponent>
    </div>
  );
};

type BannerProps = {
  data?: HomeBanner;
};

const HomePageBanner: React.FC<BannerProps> = ({ data }: BannerProps) => {
  if (data === undefined) {
    return <Default />;
  }

  const backgroundMobile = data?.backgroundMobile;
  const backgroundDesktop = data?.backgroundDesktop;
  const header = data?.header;
  const callToAction = data?.callToAction;
  const callToActionLink = data?.callToActionLink;

  let mobileHeightClass = 'h-84';
  if (process.env.GATSBY_DOMAIN_ID === 'rlp') {
    mobileHeightClass = 'h-52';
  }
  return (
    <header
      className={`bg-black py-4 relative w-full md:w-auto ${mobileHeightClass} md:h-96 overflow-hidden cursor-pointer`}
      id="home-page-banner"
    >
      <div
        className={`absolute top-0 bottom-0 left-0 right-0 w-full md:w-auto ${mobileHeightClass} md:h-96`}
      >
        {backgroundMobile && backgroundDesktop && (
          <BannerImage
            backgroundMobile={backgroundMobile}
            backgroundDesktop={backgroundDesktop}
          />
        )}
        <div className="container mx-auto">
          <div
            id="banner-main"
            className={`flex flex-col justify-end md:justify-between py-6 ${mobileHeightClass} md:h-96`}
          >
            <div className="hidden md:block">
              <LogoImage color="white" />
            </div>
            <div className="px-4 md:px-0 z-10">
              <Header1
                color="white"
                className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mb-2"
              >
                {header && <>{header}</>}
                {!header && <DefaultText />}
              </Header1>
              {callToAction && callToActionLink && (
                <CTA
                  color="white"
                  callToAction={callToAction}
                  callToActionLink={callToActionLink}
                />
              )}
            </div>
            {/* {process.env.GATSBY_DOMAIN_ID !== 'rlp' && ( */}
            {/*  <> */}
            {/*    <div className="hidden md:block"> */}
            {/*      <LogoImage color="white" /> */}
            {/*    </div> */}
            {/*    <div className="px-4 md:px-0 z-10"> */}
            {/*      <Header1 color="white" className="w-full md:w-1/2 mb-2"> */}
            {/*        {header && <>{header}</>} */}
            {/*        {!header && <DefaultText />} */}
            {/*      </Header1> */}
            {/*      {callToAction && callToActionLink && ( */}
            {/*        <CTA */}
            {/*          color="white" */}
            {/*          callToAction={callToAction} */}
            {/*          callToActionLink={callToActionLink} */}
            {/*        /> */}
            {/*      )} */}
            {/*    </div> */}
            {/*  </> */}
            {/* )} */}
            {/* {process.env.GATSBY_DOMAIN_ID === 'rlp' && ( */}
            {/*  <> */}
            {/*    <div */}
            {/*      className="hidden md:block absolute bottom-0 transform -translate-x-1/2 pb-6" */}
            {/*      style={{ left: '50%' }} */}
            {/*    > */}
            {/*      <LogoImage color="white" /> */}
            {/*    </div> */}
            {/*    <div */}
            {/*      className="block md:hidden absolute bottom-0 transform -translate-x-1/2 pb-6" */}
            {/*      style={{ left: '50%' }} */}
            {/*    > */}
            {/*      <LogoImage color="white" size="mobile" /> */}
            {/*    </div> */}
            {/*  </> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </header>
  );
};

HomePageBanner.defaultProps = {
  data: undefined,
};

export default HomePageBanner;
