import React, { useState } from 'react';
import { graphql } from 'gatsby';

import {
  HomeQuery,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import { HomeLayout } from '../components/homeLayout';
import SEO from '../components/seo';
import HomePageBanner, { HomeBanner } from '../components/homePageBanner';
import HomePageMenu from '../components/menus/homePageMenu';
import { CTAButton } from '../components/linkComponent';

import MobileHeaderMenu from '../components/menus/headerMenu/mobile';
import SideMenu from '../components/menus/sideMenu';
import USMHome from '../components/homepage/usmHome';
import RLPHome from '../components/homepage/rlpHome';
import { Menu } from '../components/menus/types';

type Props = {
  data: HomeQuery;
};

const Home: React.FC<Props> = ({ data }: Props) => {
  const [menuToggled, setMenuToggled] = useState<boolean>(false);

  const {
    allContentfulHomePageBanner: { edges: homePageBannerArray },
    allContentfulMenu: { edges: menuArray },
  } = data;

  const homePageBannersById: Record<
    string,
    HomeBanner
  > = homePageBannerArray.reduce((acc: Record<string, HomeBanner>, banner) => {
    acc[banner.node.slug || ''] = banner.node;
    return acc;
  }, {});

  const menusById: Record<string, Menu> = menuArray.reduce(
    (acc: Record<string, Menu>, menu) => {
      acc[menu.node.menuId || ''] = menu.node;
      return acc;
    },
    {}
  );

  let homePageBanner = homePageBannersById.usm;
  let menu = menusById.usm;
  if (process.env.GATSBY_DOMAIN_ID === 'rlp') {
    homePageBanner = homePageBannersById.rlp;
    menu = menusById.rlp;
  }

  return (
    <HomeLayout pathname="/">
      <SEO
        title="White Reparations to African People"
        description="Uhuru Solidarity Movement is the reparations organization of white people under the leadership of the African People's Socialist Party"
      />
      <HomePageBanner data={homePageBanner} />
      <MobileHeaderMenu onToggle={() => setMenuToggled(!menuToggled)} />
      <div className="bg-black md:bg-transparent">
        {menu && menu.ctaItem && (
          <div className="block md:hidden px-5 pt-4">
            <CTAButton link={menu.ctaItem.link || ''} className="w-full">
              {menu.ctaItem.text}
            </CTAButton>
          </div>
        )}
        <HomePageMenu
          pathname="/"
          data={menu}
          onSideMenuToggle={() => setMenuToggled(!menuToggled)}
          sideMenuToggled={menuToggled}
        />
        {process.env.GATSBY_DOMAIN_ID !== 'rlp' && <USMHome data={data} />}
        {process.env.GATSBY_DOMAIN_ID === 'rlp' && <RLPHome data={data} />}
      </div>
      <SideMenu
        pathname="/"
        toggled={menuToggled}
        onClose={() => setMenuToggled(false)}
        data={menu}
      />
    </HomeLayout>
  );
};

export const query = graphql`
  query Home {
    allContentfulHomePageBanner {
      edges {
        node {
          slug
          header
          backgroundDesktop: background {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          backgroundMobile: background {
            gatsbyImageData(
              layout: CONSTRAINED
              height: 288
              resizingBehavior: FILL
              placeholder: BLURRED
            )
          }
          callToAction
          callToActionLink
        }
      }
    }
    allContentfulMenu {
      edges {
        node {
          menuId
          items {
            id
            text
            link
            appearsOnHomepage
            subMenu {
              id
              text
              link
              appearsOnHomepage
            }
          }
          ctaItem {
            text
            link
          }
          secondaryCtaItem {
            text
            link
          }
        }
      }
    }
    allContentfulHomeContent {
      edges {
        node {
          sectionId
          header
          content {
            raw
          }
          photo {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          photoBreak: photo {
            gatsbyImageData(
              layout: CONSTRAINED
              resizingBehavior: FILL
              placeholder: BLURRED
            )
          }
          video
          callToAction
          callToActionLink
        }
      }
    }
    allContentfulFeaturedPosts {
      edges {
        node {
          listId
          name
          posts {
            id
            topic {
              name
              topicId
            }
            slug
            header
            featuredBlurb
            photoDesktop: photo {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                height: 195
                width: 195
                cropFocus: CENTER
              )
            }
            photoMobile: photo {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.75
                width: 1280
                cropFocus: CENTER
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    allContentfulFeaturedEvents {
      edges {
        node {
          listId
          name
          events {
            id
            slug
            title
            featuredBlurb
            region {
              name
              locationId
            }
            online
            photoDesktop: photo {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                height: 195
                width: 195
                cropFocus: CENTER
              )
            }
            photoMobile: photo {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.75
                width: 1280
                cropFocus: CENTER
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    allContentfulPoliticalEducationList {
      edges {
        node {
          sections {
            id
            header
            link
            photoDesktop: photo {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.75
                width: 700
                cropFocus: CENTER
                placeholder: BLURRED
              )
            }
            photoMobile: photo {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.75
                width: 1280
                cropFocus: CENTER
                placeholder: BLURRED
              )
            }
            description {
              raw
            }
          }
        }
      }
    }
  }
`;

export default Home;
