import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Header1 } from '../headers';
import ContentfulRichText from '../contentfulRichText';
import { CTAButton } from '../linkComponent';
import { HomePageContent } from './types';

import './styles/breakSection.css';

type HomePageBreakSectionProps = {
  sectionId: string;
  data: HomePageContent;
};

const HomePageBreakSection: React.FC<HomePageBreakSectionProps> = ({
  sectionId,
  data,
}: HomePageBreakSectionProps) => {
  const {
    header,
    content,
    photoBreak: photo,
    callToAction,
    callToActionLink,
  } = data;
  let leftWidthClass = 'md:w-1/2';
  if (sectionId === 'slavery-is-both-our-legacies') {
    leftWidthClass = 'md:w-7/12';
  }
  return (
    <>
      {photo && (
        <>
          <div className="w-4/6 h-full absolute right-0 top-0 hidden md:block">
            <GatsbyImage
              alt="TODO: add alt tag"
              style={{ minWidth: '100%', minHeight: '100%' }}
              image={photo?.gatsbyImageData}
            />
          </div>
          <div className="w-4/6 h-full absolute right-0 top-0 bg-gradient-to-r from-black via-transparent hidden md:block" />
        </>
      )}
      <div className="break-section container mx-auto md:pt-2 md:pb-4">
        <div className="flex flex-wrap-reverse md:flex-nowrap justify-between">
          <div
            className={`w-full ${leftWidthClass} flex-initial flex flex-col justify-between md:pr-12 z-10`}
          >
            <div className="border-t-4 border-white md:border-t-0 mx-5 md:mx-0 pt-8 md:pt-0" />
            <div>
              <Header1
                tag="h2"
                color="white"
                className="uppercase mx-5 md:mx-0"
              >
                {header}
              </Header1>
              <div className="text-white mt-2 md:pr-24 text-base">
                <ContentfulRichText document={content} />
              </div>
            </div>
            {callToAction && callToActionLink && (
              <CTAButton
                link={callToActionLink}
                className="mt-4 md:mt-8 mx-5 md:mx-0 md:w-64 flex-0"
              >
                {callToAction}
              </CTAButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageBreakSection;
