/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';
import HeaderMenu from './menus/headerMenu';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { LayoutQuery } from '../../types/graphql-types';
import { Menu } from './menus/types';
import Footer from './footer/footer';

type Props = {
  children: React.ReactNode;
};

export const HomeLayout: React.FC<Props> = ({ children }: Props) => {
  const data: LayoutQuery = useStaticQuery(graphql`
    query HomeLayout {
      allContentfulMenu {
        edges {
          node {
            menuId
            items {
              id
              text
              link
              appearsOnHomepage
              subMenu {
                id
                text
                link
                appearsOnHomepage
              }
            }
            ctaItem {
              text
              link
            }
            secondaryCtaItem {
              text
              link
            }
          }
        }
      }
    }
  `);

  // TODO: abstract. repeat from home page (move 'getContent' into a util)
  const {
    allContentfulMenu: { edges: menuArray },
  } = data;

  const menusById: Record<string, Menu> = menuArray.reduce(
    (acc: Record<string, Menu>, menu) => {
      acc[menu.node.menuId || ''] = menu.node;
      return acc;
    },
    {}
  );

  const footerMenu = menusById.footer;
  return (
    <>
      <main>{children}</main>
      <Footer FooterMenu={footerMenu} />
    </>
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeLayout;
